<template>
  <div>
    <s-dialog
      v-model:visible="showBatchModal"
      v-ada-modal="{ show: showBatchModal, first: () => { $refs.closeBtn.focus() } }"
      class="cart-batch__modal"
      :type="'full'"
    >
      <template #title>
        <div
          class="cart-list__header"
          style="border-bottom: none"
        >
          <div class="cart-list__header-title">
            <h4>
              {{ `${language.SHEIN_KEY_PC_22381} (${cartRowsNum})` }}
            </h4>
          </div>
          <a
            ref="closeBtn"
            v-enterkey
            class="cart-batch__closeBtn"
            tabindex="0"
            role="button"
            :aria-label="language.SHEIN_KEY_PC_14807"
            @click="handleClose()"
          >
            <Icon
              name="sui_icon_closed_14px_1"
              size="20px"
            />
          </a>
        </div>
      </template>

      <div 
        v-if="showBatchActive"
        v-infinite-scroll="handleInfiniteScroll"
        class="new-cart-batch"
        infinite-scroll-disabled="disabledScroll"
        infinite-scroll-nodata="noMoreData"
        infinite-scroll-distance="6000"
      >
        <template v-for="mall in mallCartList">
          <template v-for="listItem in mall.mall_list">
            <template v-if="( showOBMStore && listItem.store_code ) || ( !showOBMStore && showThreeStore && listItem.store_code )">
              <platform-store-header
                :key="mall.mall_code +'-'+listItem.store_code"
                :store-code="listItem.storeCode"
                :store-logo="listItem.store_logo"
                :store-title="listItem.store_title"
                :mall-code="mall.mall_code"
                :store-type="listItem.store_type"
                :preferred-seller-store="listItem.preferred_seller_store"
                :batch-active="true"
                :is-batch-active-page="true"
              >
                <s-checkbox
                  :label="mall.mall_code +','+listItem.store_code"
                  :model-value="isChecked(listItem.store_list_origin)"
                  gap="12px"
                  outside-check
                  @change="handleStoreCheckedItem"
                />
              </platform-store-header>
            </template>
            <template
              v-for="promotionGroup in (listItem.store_code ? listItem.store_list : [listItem])"
              :key="mall.mall_code +'-'+(promotionGroup.promotion_id || promotionGroup.id) +'-'+ listItem.store_code"
            >
              <promotion-group-header
                v-if="promotionGroup.id != 'common' && ![4,22].includes(+promotionGroup.type_id)"
                :key="promotionGroup.promotion_id+'-'+ listItem.store_code"
                :language="language"
                :abt-params="ABTParams"
                :integrity-promotion-info="promotionGroup"
                :batch-active="true"
                :is-batch-active-page="true"
              >
                <s-checkbox
                  :label="promotionGroup.promotion_id"
                  :model-value="isChecked(promotionGroup.list)"
                  gap="0px"
                  outside-check
                  @change="handleGroupCheckedItem"
                />
              </promotion-group-header>
              <div
                v-else
                :key="mall.mall_code +'-'+(promotionGroup.promotion_id || promotionGroup.id)+'-'+ listItem.store_code"
                class="common-group-header"
              ></div>
              <cart-list-item
                v-for="(item, index1) in promotionGroup.list.filter(i => renderCount > cartsId.indexOf(i.id))"
                :key="index1+item.id"
                :item="item"
                :index="cartsId.indexOf(item.id)"
                :item-promotion-group-info="promotionGroup"
                :is-inline-store="promotionGroup.subject == 1 && item.promotionGroupId && !(!showOBMStore && !showThreeStore)"
                :is-batch-active-page="true"
              >
                <div class="batch-checkbox">
                  <s-checkbox-group
                    v-model="batchEditItemIds"
                    :disabled="item.isNewcomerItem"
                    gap="0px"
                    @click="handleBatchItemClick(item)"
                  >
                    <s-checkbox
                      ref="itemChecked"
                      :label="item.id"
                      :disabled="item.isNewcomerItem"
                      gap="0px"
                      @change="handleBatchItem"
                    />
                  </s-checkbox-group>
                </div>
              </cart-list-item>
              <!-- 按店铺或促销组维度商品列表后方添加padding, 8px, 无法通过仅css实现 -->
              <div
                class="place-holder"
                style="padding-bottom: 8px;background: #fff;"
              ></div>
            </template>
          </template>
        </template>
        <template v-if="soldoutItems.length">
          <SoldOutHeader :is-batch-active-page="true" />
          <cart-list-item
            v-for="(item, index) in soldoutItems.filter(i => renderCount > cartsId.indexOf(i.id))"
            :key="index+item.id"
            :item="item"
            :index="cartsId.indexOf(item.id)"
            :is-batch-active-page="true"
          >
            <div class="batch-checkbox">
              <s-checkbox-group
                v-model="batchEditItemIds"
                gap="0px"
              >
                <s-checkbox
                  ref="itemChecked"
                  :label="item.id"
                  gap="0px"
                  @change="handleBatchItem"
                />
              </s-checkbox-group>
            </div>
          </cart-list-item>
        </template>
      </div>

      <template #footer>
        <div
          class="cart-batch__footer"
        >
          <div>
            <s-checkbox
              ref="checkedAll"
              v-model="checkAll"
              v-enterkey="{ handler: () => $refs.checkedAll.click() }"
              gap="24px"
              tabindex="0"
              role="checkbox"
              :aria-label="language.SHEIN_KEY_PC_15813"
              :aria-checked="checkAll"
              @change="handleAllChecked"
            >
              {{ language.SHEIN_KEY_PC_15813 }}
            </s-checkbox>
            <span
              class="cart-batch__selectedNum"
              tabindex="0"
            >{{ `${batchEditItemIds.length} ${language.SHEIN_KEY_PC_15659}` }}</span>
          </div>
          <span class="cart-batch__operation">
            <!-- Move to wishlist -->
            <s-button
              v-enterkey="{ handler: handleBatchM2W }"
              :type="['H36PX']"
              class="cart-batch__operation-item"
              :disabled="batchEditItemIds.length == 0"
              :tabindex="batchEditItemIds.length == 0 ? -1 : 0"
              role="button"
              da-event-click="1-7-7-8"
              @click="preHandleBatchM2W"
            >
              {{ language.SHEIN_KEY_PC_14552 }}
            </s-button>
            <!-- Delete -->
            <s-button
              v-enterkey="{ handler: handleBatchDetete }"
              :type="['H36PX']"
              class="cart-batch__operation-item"
              :disabled="batchEditItemIds.length == 0"
              :tabindex="batchEditItemIds.length == 0 ? -1 : 0"
              role="button"
              da-event-click="1-7-7-3"
              @click="preHandleBatchDetete"
            >
              {{ language.SHEIN_KEY_PC_14548 }}
            </s-button>
          </span>
        </div>
      </template>
    </s-dialog>
    <common-modal-tip :modal-options="modalOptions" />
  </div>
</template>
<script>
import cartListItem from './list/cartListItem'
import promotionGroupHeader from './list/promotionGroupHeader'
import platformStoreHeader from './platform/platformStoreHeader.vue'
import SoldOutHeader from './list/newList/SoldOutHeader.vue'

import commonModalTip from './commonModalTip.vue'
import { SMessage } from '@shein-aidc/sui-message'

import { batchDelete, batchWishGoods } from '../utils/fetcher.js'
import cartLoginView from '../utils/cartLoginView.js'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { daEventCenter } from '../../../services/eventCenter'
import { htmlDecode as _htmlDecode, template } from '@shein/common-function'
import useDeleteTips from './deleteTips/hooks/useDeleteTips'
import SimilarPopover from '../../common/similar_products'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'
import { Icon } from '@shein-aidc/icon-vue3'

daEventCenter.addSubscriber({ modulecode: '1-7-7' })

export default {
  setup() {
    const { showDeleteTips } = useDeleteTips()
    return {
      showDeleteTips,
    }
  },
  components: {
    cartListItem,
    commonModalTip,
    promotionGroupHeader,
    platformStoreHeader,
    SoldOutHeader,
    Icon
  },
  data: function () {
    return {
      showBatchModal: false,
      batchEditItemIds: [],
      checkAll: false,
      modalOptions: null,
      loaded: false,
      renderCount: 6
    }
  },
  computed: {
    ...mapState(['language', 'cartSumQuantity', 'batchActive', 'soldoutItems', 'cartsId', 'cartList', 'ABTParams', 'soldoutIds', 'mallCartList', 'integrityPromotionInfo']),
    ...mapGetters(['showOBMStore', 'showThreeStore']),
    showBatchActive() {
      return this.loaded && this.batchActive
    },
    cartRowsNum() {
      return this.cartList.length
    },
    cartListFilter() {
      return this.cartList.filter(item => !item.isNewcomerItem)
    },
    hasNewcomerItem() {
      return this.cartList.some(item => item.isNewcomerItem)
    },
    noMoreData() {
      return this.renderCount && this.renderCount >= this.cartsId.length
    },
  },
  watch: {
    showBatchModal (n, o) {
      if (n && n != o) {
        this.analysis_operator('expose_batchedit')
      }
    },
    showBatchActive (n, o) {
      if (n && n != o) {
        this.handleOpen()
      }
    },
    checkAll (n) {
      if (n) {
        this.batchEditItemIds = this.cartListFilter.map(item => item.id)
        this.analysis_operator('click_selectallproduct')
      } else {
        const cartsLength = this.cartListFilter.length
        const batchedItemsLength = this.batchEditItemIds.length

        if (cartsLength == batchedItemsLength) this.batchEditItemIds = []
      }
    },
    batchEditItemIds: {
      deep: true,
      handler (n) {
        if (n && n.length) {
          if (n.length < this.cartListFilter.length) {
            this.checkAll = false
          } else if (n.length == this.cartListFilter.length) {
            this.checkAll = true
          }
        } else {
          this.checkAll = false
        }
      }
    }
  },

  mounted() {
    this.loaded = true
  },
  methods: {
    ...mapActions(['asyncUpdateCartInfo']),
    ...mapMutations(['updateField']),
    handleNewcomerItemTips() {
      this.$message({
        type: 'info',
        message: this.language.SHEIN_KEY_PC_27125,
      })
      daEventCenter.triggerNotice({
        daId: '1-7-3-39',
      })
    },
    handleBatchItem ({ checked = false } = {}) {
      if (checked) {
        daEventCenter.triggerNotice({
          daId: '1-7-3-7',
        })
      }
    },
    handleBatchItemClick(item) {
      if (item.isNewcomerItem) {
        this.handleNewcomerItemTips()
      }
    },
    isChecked (goodsList = []) {
      return goodsList.filter(item => !item.isNewcomerItem && !this.batchEditItemIds.includes(item.id)).length ? false : true
    },
    handleGroupCheckedItem({ label = '', checked = false }) {
      let groupCartList = this.integrityPromotionInfo[label]?.list || []
      groupCartList.forEach(item => {
        if (checked) {
          if (!this.batchEditItemIds.includes(item.id) && !item.isNewcomerItem) {
            this.batchEditItemIds.push(item.id)
          }
        } else {
          let site = this.batchEditItemIds.indexOf(item.id)
          site > -1 && (this.batchEditItemIds.splice(site, 1))
        }
      })
      if (checked && this.hasNewcomerItem && groupCartList.find(f => f.isNewcomerItem)) {
        this.handleNewcomerItemTips()
      }
    },
    handleStoreCheckedItem( { label = '', checked = false }) {
      let [mall_code = '', store_code = ''] = label.split(',')
      let mallItem = this.mallCartList.find(mall => mall.mall_code == mall_code)
      let storeCartList = mallItem && mallItem.mall_list.find(item => item.store_code == store_code)?.store_list_origin || []
      storeCartList.forEach(item => {
        if (checked) {
          if (!this.batchEditItemIds.includes(item.id) && !item.isNewcomerItem) {
            this.batchEditItemIds.push(item.id)
          }
        } else {
          let site = this.batchEditItemIds.indexOf(item.id)
          site > -1 && (this.batchEditItemIds.splice(site, 1))
        }
      })
      if (checked && this.hasNewcomerItem && storeCartList.find(f => f.isNewcomerItem)) {
        this.handleNewcomerItemTips()
      }
    },
    handleAllChecked({ checked = false }) {
      if (checked === true && this.hasNewcomerItem) {
        this.handleNewcomerItemTips()
      }
    },
    handleClose () {
      this.updateField({ key: 'batchActive', value: false })
      this.updateField({ key: 'filterType', value: 'none' })
      this.showBatchModal = false
      this.batchEditItemIds = []
      this.checkAll = false
      this.renderCount = 6
    },
    handleOpen () {
      this.showBatchModal = true
      if (this.soldoutIds) {
        this.batchEditItemIds = [].concat(this.soldoutIds)
      }
    },
    handleBatchM2W () {
      const reqBatchIds = this.batchEditItemIds
      const reqCarts = [].concat(this.cartList)

      batchWishGoods({ ids: reqBatchIds })
        .then(res => {
          if (res.code == '0') {
            // SMessage({
            //   offset: 136,
            //   type: 'success',
            //   message: this.htmlDecode(this.language.SHEIN_KEY_PC_16402),
            // })
            this.handleClose()
            this.asyncUpdateCartInfo({ cartInfo: res.info })
            this.resetBatchEditItemIds()
            
            this.reflashGetCoupon()

            this.analysis_operator('expose_popup_itemwishlist', { carts: reqCarts, ids: reqBatchIds })
            this.analysis_operator('click_movewishlistyes', { carts: reqCarts, ids: reqBatchIds, result: 1 })
          } else {
            if (res.code == -4 || res.code == -2) { // 触发容灾
              SMessage({
                offset: 136,
                type: 'error',
                message: this.htmlDecode(this.language.SHEIN_KEY_PC_17753),
              })
            } else if (Number(res.code) === 400427 && res.info){ // 24小时内收藏数超过限制
              SMessage({
                offset: 136,
                type: 'info',
                message: template(res.info.limit, this.language.SHEIN_KEY_PC_19347),
              })
            } else {
              SMessage({
                offset: 136,
                type: 'error',
                message: this.htmlDecode(this.language.SHEIN_KEY_PC_17737),
              })
            }

            this.analysis_operator('expose_popup_wishlistfail')
            this.analysis_operator('click_movewishlistyes', { carts: reqCarts, ids: reqBatchIds, result: 0 })
          }
        })
    },
    handleBatchDetete (type) {
      const reqBatchIds = this.batchEditItemIds
      const reqCarts = [].concat(this.cartList)

      batchDelete({ ids: reqBatchIds })
        .then(res => {
          if (res.code == '0') {
            // SMessage({
            //   offset: 136,
            //   type: 'success',
            //   message: this.htmlDecode(this.language.SHEIN_KEY_PC_17584),
            // })
            this.handleClose()
            this.analysis_operator('expose_popup_itemdeleted', { carts: reqCarts, ids: reqBatchIds })
            this.analysis_operator('click_deleteproductsyes', { carts: reqCarts, ids: reqBatchIds, result: 1 })
            if (type === 'showSimilar') {
              const products = reqCarts.filter(item => reqBatchIds.includes(item.id)).map(item => item.product)
              const goodsList = products.map(item => {
                return {
                  cate_id: item.cat_id,
                  goods_id: item.goods_id,
                }
              })
              this.handleShowSimilarModal(products[0], { recAnalysis: { activeFrom: 'batch_deleteproducts' },  goodsList, isForceUpdate: true, extendTitle: this.language.SHEIN_KEY_PC_16963, desc: this.language.SHEIN_KEY_PC_27978, titleConfig: { enValue: 'batch_deleteproducts' } })
            }
            this.asyncUpdateCartInfo({ cartInfo: res.info })
            this.resetBatchEditItemIds()
          } else {
            SMessage({
              offset: 136,
              type: 'error',
              message: this.htmlDecode(this.language.SHEIN_KEY_PC_17700),
            })

            this.analysis_operator('click_deleteproductsyes', { carts: reqCarts, ids: reqBatchIds, result: 0 })
            this.analysis_operator('expose_popup_deletefail')
          }
        })
    },
    // 领券接口更新
    reflashGetCoupon () {
      this.updateField({
        key: 'isReflashGetCouponFetch',
        value: true
      })
    },
    preHandleBatchM2W () {
      if (this.batchEditItemIds.length == 0) return

      this.modalOptions = {
        show: true,
        text: this.htmlDecode(this.language.SHEIN_KEY_PC_17955),
        okCallback: () => {
          this.modalOptions.show = false
          const isLogin = !!isLoginFn()
          if (isLogin) {
            this.handleBatchM2W()
          } else {
            let loginBeforeCheckedGoodsid = this.cartList.filter(item=> this.batchEditItemIds.includes(item.id)).map(item => (item.product.goods_id + ',' + item.product.sku_sale_attr?.map(attr => attr.attr_value_id).join() || ''))
            cartLoginView.login({
              type: 'batchWishGoods',
              beforeCartSumQuantity: this.cartSumQuantity,
              cb: ({ equalCart }, res) => {
                if(res.code == 0) {
                  this.asyncUpdateCartInfo({ cartInfo: res.info })
                  let carts = res.info.carts
                  if (carts && carts.length) {
                    this.batchEditItemIds = carts.filter(item => loginBeforeCheckedGoodsid.includes((item.product.goods_id + ',' + item.product.sku_sale_attr?.map(attr => attr.attr_value_id).join() || ''))).map(item => item.id)
                  }
                }
                if (equalCart) {
                  this.handleBatchM2W()
                } else {
                  SMessage({
                    offset: 136,
                    type: 'info',
                    message: this.htmlDecode(this.language.SHEIN_KEY_PC_17957),
                    duration: 5000
                  })
                }
              }
            })
          }
        },
        cancelCallback: () => {
          this.modalOptions.show = false
          this.analysis_operator('click_movewishlistno')
        }
      }
    },
    preHandleBatchDetete () {
      if (this.batchEditItemIds.length == 0) return
      // 展示新版删除弹窗
      const showDeleteTipsRsult = this.showDeleteTips({
        cartAbtInfo: this.ABTParams,
        language: this.language,
        isBatch: true,
        onDelete: () => {
          this.reflashGetCoupon()
          this.handleBatchDetete()
        },
        onMoveToWishList: async () => {
          const isLogin = !!isLoginFn()
          if (isLogin) {
            this.handleBatchM2W()
          } else {
            let loginBeforeCheckedGoodsid = this.cartList.filter(item=> this.batchEditItemIds.includes(item.id)).map(item => (item.product.goods_id + ',' + item.product.sku_sale_attr?.map(attr => attr.attr_value_id).join() || ''))
            cartLoginView.login({
              type: 'batchWishGoods',
              beforeCartSumQuantity: this.cartSumQuantity,
              cb: ({ equalCart }, res) => {
                if(res.code == 0) {
                  this.asyncUpdateCartInfo({ cartInfo: res.info })
                  let carts = res.info.carts
                  if (carts && carts.length) {
                    this.batchEditItemIds = carts.filter(item => loginBeforeCheckedGoodsid.includes((item.product.goods_id + ',' + item.product.sku_sale_attr?.map(attr => attr.attr_value_id).join() || ''))).map(item => item.id)
                  }
                }
                if (equalCart) {
                  this.handleBatchM2W()
                } else {
                  SMessage({
                    offset: 136,
                    type: 'info',
                    message: this.htmlDecode(this.language.SHEIN_KEY_PC_17957),
                    duration: 5000
                  })
                }
              }
            })
          }
        },
        onDeleteAndFindSimilar: () => {
          this.reflashGetCoupon()
          this.handleBatchDetete('showSimilar')
        },
      })
      if (showDeleteTipsRsult) return      
      this.modalOptions = {
        show: true,
        text: this.language.SHEIN_KEY_PC_16929,
        okCallback: () => {
          this.reflashGetCoupon()
          this.modalOptions.show = false
          this.handleBatchDetete()
        },
        cancelCallback: () => {
          this.modalOptions.show = false
          this.analysis_operator('click_deleteproductsno')
        }
      }
    },
    resetBatchEditItemIds () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'  // 可选，平滑滚动到顶部
      })
    },

    analysis_operator (type, payload) {
      switch (type) {
        case 'expose_popup_deletefail':
          daEventCenter.triggerNotice({
            daId: '1-7-7-1',
          })
          break
        case 'expose_popup_wishlistfail':
          daEventCenter.triggerNotice({
            daId: '1-7-7-2',
          })
          break
        case 'click_deleteproductsno':
          daEventCenter.triggerNotice({
            daId: '1-7-7-4',
          })
          break
        case 'click_deleteproductsyes':
          daEventCenter.triggerNotice({
            daId: '1-7-7-5',
            extraData: {
              carts: payload.carts, batchIds: payload.ids, result: payload.result
            }
          })
          break
        case 'expose_popup_itemdeleted':
          daEventCenter.triggerNotice({
            daId: '1-7-7-6',
            extraData: {
              carts: payload.carts, batchIds: payload.ids
            }
          })
          break
        case 'expose_popup_itemwishlist':
          daEventCenter.triggerNotice({
            daId: '1-7-7-7',
            extraData: {
              carts: payload.carts, batchIds: payload.ids
            }
          })
          break
        case 'click_movewishlistno':
          daEventCenter.triggerNotice({
            daId: '1-7-7-9',
          })
          break
        case 'click_movewishlistyes':
          daEventCenter.triggerNotice({
            daId: '1-7-7-10',
            extraData: {
              carts: payload.carts, batchIds: payload.ids, result: payload.result
            }
          })
          break
        case 'click_selectallproduct':
          daEventCenter.triggerNotice({
            daId: '1-7-7-11',
            extraData: {
              carts: this.cartList
            }
          })
          break
        case 'expose_batchedit':
          daEventCenter.triggerNotice({
            daId: '1-7-7-12'
          })
          break
      }
    },
    htmlDecode: function (text){
      return _htmlDecode({ text: text })
    },
    handleShowSimilarModal(product, config) {
      SimilarPopover.showModal(product, config)
    },
    handleInfiniteScroll () {
      if(this.noMoreData) return
      requestAnimationFrame(() => {
        this.renderCount = this.renderCount + 6
      })
      
    },
  }
}
</script>
<style lang="less">
.cart-batch-img{
  padding-left: 24px;
}
.cart-batch__selectedNum{
  font-size: 16px;
  color: #999;
  position: relative;
  bottom: -2px;
}
.cart-batch__modal .sui-dialog__wrapper .sui-dialog__title {
  /* stylelint-disable-next-line selector-max-specificity */
  .cart-list__header {
    padding: 12px 16px;
  }
}
</style>

<style lang="less" scoped>
.new-cart-batch {
  margin-bottom: 20px;
  .batch-checkbox{
    line-height: unset;
    display: flex;
    align-items: center;
  }
  /* stylelint-disable-next-line selector-max-specificity */
  :deep(.cart_item-header.promo-group) {
    background: #FFF6F3;
    padding: 12px 16px;
    margin-top: 1px;
    /* stylelint-disable-next-line selector-max-specificity */
    section {
      padding: 2px 0;
    }
  }
  /* stylelint-disable-next-line selector-max-specificity */
  :deep(.cart_item-header.not-promoGoods) {
    padding: 0 16px;
    margin-top: 1px;
  }
  .common-group-header {
    padding: 0px 16px;
    height: 1px;
    background: #fff;
  }
  .place-holder + .common-group-header {
    position: relative;
    &:before {
      border-bottom: 1px dashed #e5e5e5;
      content: "";
      width: calc(100% - 32px);
      display: block;
      top: 0;
      position: absolute;
    }
  }
}
.cart-batch__modal {
  :deep(.sui-dialog__title-wrap) {
    /* stylelint-disable-next-line declaration-no-important */
    padding: 0 83px!important;
    background: #f4f4f4;
  }
  :deep(.sui-dialog__body::-webkit-scrollbar) {
    display: none;
  }
  :deep(.sui-dialog__body) {
    background: #f4f4f4;
    position: relative;
    overflow-x: hidden;
    padding: 0 83px;
  }
  :deep(.sui-dialog__title){
    /* stylelint-disable-next-line declaration-no-important */
    margin-bottom: 0!important;
  }
  .cart-batch__footer {
    position: fixed;
    width: 100%;
    height: 68px;
    bottom: 0;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 83px;
    border-top: 1px solid #E5E5E5;
    z-index: @zindex-hack;
    left: 0;
    >div:first-child {
      padding-left: 16px;
    }
  }
  .cart-batch__closeBtn {
    position: absolute;
    top: 22px;
    right: 21px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
    &:hover,&:focus{
      text-decoration: none;
    }
  }
  .cart-batch__operation-item {
    margin-left: 24px;
    font-weight: bold;
  }
}
</style>
